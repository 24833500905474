import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import FormButtons from 'components/core/form/buttons';
import UpdateBudgetHeader from './header';

import classNames from 'classnames';
import { bolderTextClasses, spaceBorderTableClasses } from 'pages/private/operationals/dre';
import UpdateBudgetTable from './budget-table';
import UpdateServicesTables from './services-table';
import { useEffect } from 'react';
import { INITIAL_COMMERCIAL_BUDGET_VERSION, PERCENTAGE_VALUE, commercialBudgetVersionSchema, getDirectCosts, getIndirectCosts } from '../utils';
import { BudgetStatus, CommercialBudgetOutletContext, UpdateBudgetPayload } from 'types/models/commercial';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from 'components/core/spinner';
import { isEmpty } from 'lodash';
import { projectTypeTitleByIdMap } from 'utils/project';
import Empty from 'components/empty';
import { getNumberValueOrZero } from 'utils/number';

type UpdateCommercialBudgetVersionProps = {
    onSubmit: (data: UpdateBudgetPayload) => void;
    contractValueState: [number, React.Dispatch<React.SetStateAction<number>>];
    isSubmitting: boolean;
    isLoading: boolean;
    defaultValues?: UpdateBudgetPayload;
    version?: number;
};

export const generateTableClasses = (isBolder = false) => classNames('!text-xs !text-base-500', spaceBorderTableClasses, isBolder && bolderTextClasses);

const UpdateCommercialBudgetVersion = ({ onSubmit, contractValueState, isSubmitting, isLoading, defaultValues, version }: UpdateCommercialBudgetVersionProps) => {
    const [contractValue, setContractValue] = contractValueState;

    const { projectTypeId, budgetVersionId, budgetId } = useParams();
    const { pathname } = useLocation();

    const { commercialBudget } = useOutletContext<CommercialBudgetOutletContext>() || {};

    const isDetails = pathname.includes('visualizar');
    const isEdit = pathname.includes('editar');
    const cannotEdit = !(commercialBudget?.budgetStatus !== BudgetStatus.Started && isEdit);

    const methods = useForm<UpdateBudgetPayload>({
        mode: 'onSubmit',
        defaultValues: INITIAL_COMMERCIAL_BUDGET_VERSION,
        resolver: yupResolver(commercialBudgetVersionSchema(projectTypeId))
    });

    const { handleSubmit, watch, reset, formState, control } = methods;

    const { replace } = useFieldArray({ name: 'selectedServices', control });

    const navigate = useNavigate();
    const handleClose = () => navigate(-1);

    const values = watch();

    const { thirdPartyExpenses = [], otherExpenses = [], teamProductionExpenses = [] } = values;

    const services = [...thirdPartyExpenses, ...otherExpenses, ...teamProductionExpenses].filter(({ service }) => service);
    const indirectCostsValue = getIndirectCosts(values, contractValue);
    const directCostsValue = getDirectCosts(values, contractValue, true, false, projectTypeId);

    const marginValue = contractValue - (indirectCostsValue + directCostsValue);
    const marginPercentage = getNumberValueOrZero(marginValue / contractValue) * PERCENTAGE_VALUE;
    const marginByMonthValue = marginValue / (values.deadline || 0);
    const footageSaleValue = contractValue / (values.footage || 0) || 0;

    const isWithoutExpenses = (formState.errors as any).selectedServices?.message;

    const handleGoToBudgetDetails = () => {
        navigate(`/app/fp/${budgetId}/versoes/${budgetVersionId}/${projectTypeId}/visualizar`);
    };

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            reset(defaultValues, { keepDefaultValues: true });
        }
    }, [defaultValues, projectTypeId, reset]);

    useEffect(() => {
        if (!services.length) {
            setContractValue(0);

            if (!!values.selectedServices.length) {
                replace([]);
            }
        }
    }, [services, values.selectedServices, setContractValue, replace]);

    return (
        <Modal
            contentClassnames="w-[1300px]"
            onClose={handleClose}
            closeOnClickOutside={false}
            headerClassnames="sticky top-0 bg-white z-50 !border-b-0"
            headerLeft={
                <Text as="h3" variant="h3" className="mr-4 !text-3xl font-medium text-heading">
                    {isDetails ? 'Visualizar' : 'Atualizar'} {projectTypeTitleByIdMap[projectTypeId!] || '-'} - Versão {version || '-'}
                </Text>
            }>
            {isLoading ? (
                <Spinner className="my-10" fixed={false} />
            ) : cannotEdit ? (
                <>
                    <UpdateBudgetHeader
                        contractValue={contractValue}
                        directCostsValue={directCostsValue}
                        indirectCostsValue={indirectCostsValue}
                        margin={{ value: marginValue, percentage: marginPercentage }}
                        footageSale={footageSaleValue}
                        marginByMonth={marginByMonthValue}
                        isDisabled={false}
                    />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <UpdateBudgetTable
                                contractValue={contractValue}
                                directCostsValue={directCostsValue}
                                indirectCostsValue={indirectCostsValue}
                                margin={{ value: marginValue, percentage: marginPercentage }}
                                isDisabled={isDetails}
                            />
                            {Boolean(services.length) && <UpdateServicesTables services={services} onSetContractValue={setContractValue} contractValue={contractValue} isDisabled={isDetails} />}
                            {isWithoutExpenses && (
                                <Text variant="body.regular.sm" className="text-system-danger-500 p-6 block">
                                    Selecione ao menos menos um custo direto!
                                </Text>
                            )}
                            {!isDetails && <FormButtons className="mb-6 mt-8 px-6" isLoading={isSubmitting} />}
                        </form>
                    </FormProvider>
                </>
            ) : (
                <Empty className="p-6" title="Só é permitido a visualização da versão dessa FP." buttonLabel="Ir para o detalhes da versão." onClick={handleGoToBudgetDetails} />
            )}
        </Modal>
    );
};

export default UpdateCommercialBudgetVersion;
