import { useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CreateBidProposal from './bid';
import CreateManagementProposal from './management';
import { CreateProposalPageProps } from './utils/types';
import useGetProjectType from 'services/queries/projects/use-get-project-type';
import { Project, ProjectDeadline, ProjectProposalPayload, ProjectStatusEnum, ProjectTypeSlug } from 'types/models/project';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';
import useCreateOrUpdateProjectProposal from 'services/queries/projects/use-create-or-update-project-proposal-type';
import { toCurrencyObject } from 'utils/object';
import { omit } from 'lodash';
import CreateComplementaryProjectProposal from './complementary-project';
import CreateProjectProposal from './project';

const CreateProposalPage = () => {
    const navigate = useNavigate();
    const { projectProposalId, projectTypeId, projectId, proposalId } = useParams();

    const ctx = useOutletContext<{ projectDetails?: Partial<Project> }>();
    const hasBudgetId = Boolean(ctx?.projectDetails?.budgets?.length);

    const { data, isLoading: isLoadingProjectType, isFetched } = useGetProjectType(projectTypeId);

    const { mutateAsync: createOrUpdateProposalType, isLoading: isSubmitting } = useCreateOrUpdateProjectProposal(Number(projectId), Number(proposalId));

    const { data: proposalDetails } = useGetProjectProposalType(proposalId ? +proposalId : undefined);

    const isPreTap = useMemo(() => {
        if (Boolean(proposalDetails?.id)) {
            return proposalDetails?.project_proposal?.project?.project_status?.slug === ProjectStatusEnum.PreTap;
        }

        return true;
    }, [proposalDetails?.project_proposal?.project?.project_status?.slug, proposalDetails?.id]);

    useEffect(() => {
        if (isFetched && !data) {
            navigate(-1);
        }
    }, [data, isFetched, navigate]);

    const isBuilding = useMemo(() => isLoadingProjectType, [isLoadingProjectType]);

    const Component = useMemo(() => {
        const items = new Map<ProjectTypeSlug | undefined, (props: CreateProposalPageProps) => JSX.Element>([
            [ProjectTypeSlug.Management, CreateManagementProposal],
            [ProjectTypeSlug.Projects, CreateProjectProposal],
            [ProjectTypeSlug.Bid, CreateBidProposal],
            [ProjectTypeSlug.ComplementaryProjects, CreateComplementaryProjectProposal]
        ]);

        return items.get(data?.slug) ?? CreateManagementProposal;
    }, [data?.slug]);

    const handleSubmit = async (data: ProjectProposalPayload) => {
        try {
            const deadlineType = data.startDate || data.endDate ? ProjectDeadline.Period : ProjectDeadline.Duration;

            const payload = {
                ...omit(data, ['selectedPhases', 'weekDays', 'hasPhases', 'financialSetups']),
                type: Number(projectTypeId),
                deadlineType,
                ...(!!data.budget && { budget: data.budget.floatValue }),
                ...(!!data.investment && { investment: data.investment.floatValue }),
                ...(!!data.valueOfEntryInstallment && { valueOfEntryInstallment: data.valueOfEntryInstallment?.floatValue }),
                ...(!!data.valueOfManagementHours && { valueOfManagementHours: data.valueOfManagementHours.floatValue }),
                ...(!!data.valueOfExecutionHours && { valueOfExecutionHours: data.valueOfExecutionHours.floatValue }),
                ...(!!data.budgetExpense && { budgetExpense: data.budgetExpense.floatValue }),
                ...(!!data.budgetProvider && { budgetProvider: data.budgetProvider.floatValue }),
                ...(!!data.percentageCostAdministrative && { percentageCostAdministrative: data.percentageCostAdministrative.floatValue }),
                ...(!!data.percentageCostTax && { percentageCostTax: data.percentageCostTax.floatValue }),
                ...(!!data.percentageProfit && { percentageProfit: data.percentageProfit.floatValue }),
                ...(!!data.numberOfManagementHours && { numberOfManagementHours: data.numberOfManagementHours.floatValue }),
                ...(!!data.numberOfExecutionHours && { numberOfExecutionHours: data.numberOfExecutionHours.floatValue }),
                ...(isPreTap && {
                    ...(!!data.financialSetups?.length && {
                        financialSetups: data.financialSetups?.map((item) => ({
                            budgetProvider: item.budget_provider || 0,
                            numberOfExecutionHours: item?.number_of_execution_hours || 0,
                            valueOfExecutionHours: item.value_of_execution_hours || 0,
                            service: item.service.value
                        }))
                    }),
                    hasPhases: Boolean(data.phases?.length),
                    ...(Boolean(data.phases?.length) && {
                        phases: (data.phases || []).map((item, index) => {
                            delete item['hasThirdPart'];
                            delete item['projectServices'];

                            return {
                                classification: item?.classification,
                                paymentPercentage: (item?.paymentPercentage && +item.paymentPercentage) || 0,
                                classifications: item.classifications,
                                title: item?.title,
                                order: (item.order && +item.order) || index + 1,
                                ...(item.value && { value: item.value }),
                                ...(item.duration && { duration: item.duration }),
                                ...(item.financialPrevision && { financialPrevision: item.financialPrevision })
                            };
                        })
                    }),
                    ...(data.weekDays && { weekDays: data.weekDays?.join(';') })
                })
            };

            await createOrUpdateProposalType(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo(
        () =>
            ({
                startDate: proposalDetails?.start_date!,
                deadlineType: proposalDetails?.deadline_type!,
                director: proposalDetails?.director?.id!,
                duration: proposalDetails?.duration?.toString()!,
                endDate: proposalDetails?.end_date!,
                hasEntryInstallment: proposalDetails?.has_entry_installment,
                methodology: proposalDetails?.project_methodology?.id!,
                observations: proposalDetails?.observations! || '',
                ...(!hasBudgetId && {
                    budget: toCurrencyObject(proposalDetails?.budget!),
                    budgetExpense: toCurrencyObject(proposalDetails?.budget_expense!),
                    budgetProvider: toCurrencyObject(proposalDetails?.budget_provider!),
                    investment: toCurrencyObject(proposalDetails?.investment!),
                    investmentType: proposalDetails?.investment_type?.toString()!,
                    numberOfExecutionHours: toCurrencyObject(proposalDetails?.number_of_execution_hours!),
                    numberOfManagementHours: toCurrencyObject(proposalDetails?.number_of_management_hours!),
                    percentageCostAdministrative: toCurrencyObject(proposalDetails?.percentage_cost_administrative!),
                    percentageCostTax: toCurrencyObject(proposalDetails?.percentage_cost_tax!),
                    percentageProfit: toCurrencyObject(proposalDetails?.percentage_profit!),
                    valueOfExecutionHours: toCurrencyObject(proposalDetails?.value_of_execution_hours!),
                    valueOfManagementHours: toCurrencyObject(proposalDetails?.value_of_management_hours!)
                }),
                ...(isPreTap && {
                    hasPhases: proposalDetails?.has_phases,
                    phases:
                        proposalDetails?.phases?.map((item) => ({
                            id: item?.id,
                            paymentPercentage: item?.payment_percentage,
                            classification: item?.classification_id!,
                            classifications: item.classification?.classifications?.map((classification) => classification?.id),
                            order: item?.order_phase,
                            title: item?.title,
                            duration: item?.duration,
                            project_proposal_type_phase_has_classifications: item.project_proposal_type_phase_has_classifications || [],
                            financialPrevision: item.financial_prevision,
                            value: item.value
                        })) || [],
                    services: proposalDetails?.project_proposal_type_has_services?.map((item) => item?.project_service?.id!)!,
                    weekDays: proposalDetails?.week_days?.split(';').map((weekDay) => +weekDay),
                    selectedPhases: proposalDetails?.phases?.map((item) => item.classification_id) || [],
                    valueOfEntryInstallment: toCurrencyObject(proposalDetails?.value_of_entry_installment!),
                    ...(!hasBudgetId && {
                        financialSetups: proposalDetails?.financial_setup?.map((item) => ({
                            is_internal: item?.is_internal,
                            budget_provider: item?.budget_provider,
                            value_of_execution_hours: item?.value_of_execution_hours,
                            number_of_execution_hours: item?.number_of_execution_hours,
                            service: item?.service
                        }))
                    })
                })
            } || []),
        [proposalDetails, isPreTap, hasBudgetId]
    );

    return (
        <Component
            projectProposalId={projectProposalId || ''}
            projectTypeId={projectTypeId || ''}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            projectStatusSlug={proposalDetails?.project_proposal?.project?.project_status?.slug || ProjectStatusEnum.PreTap}
            projectDetails={ctx.projectDetails}
        />
    );
};
export default CreateProposalPage;
