import { useCallback, useContext, useMemo, useState } from 'react';

// Dependencies
import Grid from '@mui/material/Grid/Grid';
import { Outlet, useNavigate } from 'react-router-dom';

// Components
import BID from './components/type/bid';
import Card from 'components/core/card';
import ReleaseTap from './components/tap';
import Spinner from 'components/core/spinner';
import PageHeader from 'components/page/header';
import Activities from './components/activities';
import ProjectDetails from './components/details';
import Management from './components/type/management';

// Helpers
import { ProjectContext } from 'contexts/project';
import { ActivityItemProps } from './components/activities/item';
import { ProjectTypeAccordion, ProjectTypeProps } from './components/type/types';
import { Project as ProjectModel, ProjectProposalType, ProjectStatusEnum, ProjectType, ProjectTypeSlug, ProjectTypeStatus } from 'types/models/project';
import ContractDetailsActions from './components/actions/actions';
import ComplementaryProject from './components/type/complementary-project';
import ArchitecturalProject from './components/type/project';
import useGetCommercialBudgetVersions from 'services/queries/commercial/use-get-budget-versions';
import ProjectFinancial from './components/financial';
import UpdateProjectStatus from './components/update-project-status';
import useGetProjectMovements from 'services/queries/operationals/use-get-project-movements';
import ProjectMovementItem from './components/activities/project-movement';
import Modal from 'components/core/modal';
import Text from 'components/core/text';

type ProjectProps = {
    isLoadingProjectProposalTypes: boolean;
    project: Partial<ProjectModel>;
    projectProposalTypes?: Partial<ProjectProposalType & ProjectType>[];
};

const releasedTapsArray = [ProjectStatusEnum.Tap, ProjectStatusEnum.Partial, ProjectStatusEnum.Stopped, ProjectStatusEnum.Done];
const finalizedOrCancelledStatuses = [ProjectStatusEnum.Canceled, ProjectStatusEnum.Done];

const Project = ({ isLoadingProjectProposalTypes, project, projectProposalTypes = [] }: ProjectProps) => {
    const [observationContent, setObservationContent] = useState('');

    const navigate = useNavigate();
    const { state, dispatch } = useContext(ProjectContext);

    const hasBudgetId = Boolean(project?.budgets?.[0]?.id);

    const { data: budgetVersions = [], isLoading: isLoadingBudgetVersions } = useGetCommercialBudgetVersions(hasBudgetId ? String(project.budgets![0].id) : undefined);
    const { data: projectMovements = [], isLoading: isLoadingProjectMovements } = useGetProjectMovements(project.id, false);

    const [activeAccordion, setActiveAccordion] = useState<ProjectTypeProps['activeAccordion']>(ProjectTypeAccordion.Management);
    const [updateProjectStatus, setUpdateProjectStatus] = useState<ProjectStatusEnum | null>(null);

    const handleToggleAccordion = (panel: ProjectTypeProps['activeAccordion'], expanded: boolean) => setActiveAccordion(expanded ? panel : ProjectTypeAccordion.ClosedAccordion);

    const renderType = (projectType: Partial<ProjectType>) => {
        const components = new Map<ProjectTypeSlug, (props: ProjectTypeProps) => JSX.Element>([
            [ProjectTypeSlug.Management, Management],
            [ProjectTypeSlug.Projects, ArchitecturalProject],
            [ProjectTypeSlug.Bid, BID],
            [ProjectTypeSlug.ComplementaryProjects, ComplementaryProject]
        ]);

        const Component = components.get(projectType.slug!);

        if (!Component) {
            return null;
        }

        const handleAddResources = (proposalTypeId?: number) => navigate(`tipo/${proposalTypeId}/recursos`);

        const getProposalTypeBygSlug = (proposalTypeArr: Partial<ProjectProposalType>[] = []) => {
            return proposalTypeArr.find((item) => item.project_type?.slug === projectType.slug);
        };

        const proposalTypeBySlugWithBudgets = getProposalTypeBygSlug(projectProposalTypes);
        const proposalTypeBySlug = getProposalTypeBygSlug(project.proposal?.proposal_types);

        return (
            <Component
                activeAccordion={activeAccordion}
                key={projectType.id}
                projectProposalId={project.proposal?.id || 0}
                projectProposalTypeId={hasBudgetId ? proposalTypeBySlugWithBudgets?.id : proposalTypeBySlug?.id}
                projectTypeId={projectType.id || 0}
                managementInformations={project.project_management_informations}
                onAddResource={handleAddResources}
                onToggleAccordion={handleToggleAccordion}
            />
        );
    };

    const handleGoTo = useCallback((path: string) => () => navigate(path), [navigate]);

    const renderProjectTypes = (projectTypes) => {
        return projectTypes.filter((item) => item.project_type_status !== ProjectTypeStatus.InCreation).map((item) => renderType(item.project_type!));
    };

    const handleReleaseTap = useCallback(() => {
        const isReadyToRelease = project.proposal?.proposal_types?.every(
            (item) => item.project_type_status !== ProjectTypeStatus.InCreation && project.project_status?.slug === ProjectStatusEnum.PreTap
        );

        if (!isReadyToRelease) {
            return alert('O TAP só pode ser liberado após o preenchimento de todas as propostas');
        }

        dispatch({ type: 'SET_RELEASE_TAP_MODAL', payload: true });
    }, [project.proposal?.proposal_types, project?.project_status?.slug, dispatch]);

    const activities = useMemo<ActivityItemProps[]>(() => {
        const createActivityItem = (item: Partial<ProjectProposalType & ProjectType>): ActivityItemProps => {
            const isInCreation = item.project_type_status === ProjectTypeStatus.InCreation;

            const withBudgetPath = `proposta/${project.proposal?.id}/tipo/${item.project_type?.id}/editar/${item.id}`;
            const withoutBudgetPath = `proposta/${project.proposal?.id}/tipo/${item.id}`;

            return {
                title: item.project_type?.title || item.title || '-',
                helperText: 'Deseja criar a proposta agora?',
                button: {
                    action: handleGoTo(hasBudgetId ? withBudgetPath : withoutBudgetPath),
                    label: 'Configurar proposta'
                },
                done: hasBudgetId ? !isInCreation : !!item.project_proposal_types?.length,
                doneText: `Proposta de ${item.project_type?.title || item.title} criada`
            };
        };

        const activitiesItems = projectProposalTypes.map(createActivityItem);

        const tap = {
            title: 'TAP',
            helperText: 'Deseja liberar o TAP para este contrato?',
            button: { action: handleReleaseTap, label: 'Liberar TAP' },
            done: releasedTapsArray.includes(project.project_status?.slug!) || false,
            doneText: 'TAP liberado'
        };

        const movements = {
            title: 'Movimentações',
            doneText: '',
            done: false,
            helperText: Boolean(projectMovements.length)
                ? projectMovements.map((item, index) => <ProjectMovementItem key={`project_movements_${index}`} item={item} onSetObservationContent={setObservationContent} />)
                : 'Não há movimentos a serem mostrados.'
        };

        return [...activitiesItems, tap, movements];
    }, [project.project_status, projectProposalTypes, handleGoTo, handleReleaseTap, project.proposal?.id, hasBudgetId, projectMovements]);

    const canShowActions = finalizedOrCancelledStatuses.includes(project.project_status?.slug!);

    return (
        <>
            <PageHeader className="mb-6" title="" />
            <Grid container={true} spacing={2}>
                <Grid className="md:sticky md:top-0 md:h-full" item={true} xs={12} md={3}>
                    <Card elevation={21} className="mb-4">
                        <ProjectDetails {...project} />
                        {!canShowActions && (
                            <Grid alignItems="center" justifyContent="center" display="flex" className="mb-4">
                                <ContractDetailsActions
                                    onSetUpdateProjectStatus={setUpdateProjectStatus}
                                    projectStatus={project.project_status?.slug}
                                    hasBudgetId={hasBudgetId}
                                    contractId={project.id}
                                />
                            </Grid>
                        )}
                    </Card>
                    <Card elevation={21} className="mb-4">
                        {isLoadingProjectProposalTypes || isLoadingProjectMovements ? (
                            <Spinner fixed={false} size={18} parentClasses="p-3" />
                        ) : (
                            <Activities title="Atividades" activities={activities} className="px-6 py-5 max-h-[500px] overflow-auto" />
                        )}
                    </Card>
                </Grid>
                <Grid item={true} xs={12} md={9}>
                    {isLoadingBudgetVersions ? (
                        <Card className="min-h-[200px] mb-4 relative">
                            <Spinner fixed={true} />
                        </Card>
                    ) : (
                        <ProjectFinancial budgetVersions={budgetVersions} project={project} projectProposalTypes={projectProposalTypes} />
                    )}

                    {hasBudgetId ? renderProjectTypes(projectProposalTypes) : renderProjectTypes(project.proposal?.proposal_types)}
                </Grid>
            </Grid>
            <Outlet context={{ projectDetails: project }} />
            {state.isReleaseTapModalOpen && <ReleaseTap />}
            {Boolean(updateProjectStatus) && <UpdateProjectStatus updateProjectStatus={updateProjectStatus!} onSetProjectStatus={setUpdateProjectStatus} />}
            {Boolean(observationContent) && (
                <Modal contentClassnames="py-5 px-7 w-[600px]" onClose={setObservationContent.bind(this, '')}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observationContent }} />
                </Modal>
            )}
        </>
    );
};

export default Project;
