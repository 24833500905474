import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import FormButtons from 'components/core/form/buttons';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useGetCustomersOptions from 'services/queries/customers/use-get-customers-options';
import useGetProjectTypesOptions from 'services/queries/projects/use-get-project-types';
import { CommercialBudgetPayload } from 'types/models/commercial';
import { commercialBudgetFPSchema } from './utils';

type CreateOrUpdatecommercialFPProps = {
    isSubmitting: boolean;
    isLoading: boolean;
    defaultValues?: CommercialBudgetPayload;
    onSubmit: (data: CommercialBudgetPayload) => void;
};

const getInitialCommercialFP = (hasProjectId = false) => ({
    ...(!hasProjectId && {
        title: ''
    }),
    code: ''
});

const CreateOrUpdateCommercialFP = ({ isSubmitting, isLoading, onSubmit }: CreateOrUpdatecommercialFPProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { projectId } = useParams();

    const { data: customers = [], isLoading: isLoadingCustomers } = useGetCustomersOptions();
    const { data: projectTypes = [], isLoading: isLoadingProjectTypes } = useGetProjectTypesOptions();

    const { formState, control, handleSubmit } = useForm<CommercialBudgetPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(commercialBudgetFPSchema(!!projectId)),
        defaultValues: getInitialCommercialFP(!!projectId)
    });

    return (
        <Modal contentClassnames="w-[960px]" onClose={navigate.bind(this, -1)}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5 text-xl">
                        Nova FP {Boolean(projectId) ? '- Aditivo de Contrato' : ''}
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                        <Controller control={control} name="code" render={({ field }) => <Input {...field} label="Código" error={formState?.errors?.code?.message} />} />
                        {!projectId && (
                            <>
                                <Controller
                                    control={control}
                                    name="title"
                                    render={({ field }) => <Input {...field} label="Título" parentClassName="col-span-2" error={formState.errors.title?.message} />}
                                />
                                <Controller
                                    name="customer"
                                    control={control}
                                    render={({ field }) => {
                                        const value = customers.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                isLoading={isLoadingCustomers}
                                                options={customers}
                                                label="Cliente"
                                                placeholder="Selecione uma opção"
                                                error={formState.errors.customer?.message}
                                                onChange={(option: any) => field.onChange(option?.value)}
                                            />
                                        );
                                    }}
                                />
                            </>
                        )}
                        <Controller
                            name="types"
                            control={control}
                            render={({ field }) => {
                                const value = projectTypes.filter((item) => field.value?.find((value) => value === item.value));

                                return (
                                    <Select
                                        {...field}
                                        parentClassName="col-span-2"
                                        value={value}
                                        isMulti={true}
                                        isLoading={isLoadingProjectTypes}
                                        options={projectTypes}
                                        label="Tipos"
                                        placeholder="Selecione uma opção"
                                        error={(formState as any).errors.types?.message}
                                        onChange={(option: any) => {
                                            const ids = option.map((item) => item.value);

                                            field.onChange(ids);
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>
                    <FormButtons className="mt-8" isLoading={isSubmitting} />
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateCommercialFP;
